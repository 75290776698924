import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Row, Col, Empty, Modal, Card, Typography, Alert, Form, Input, Checkbox, Button, Space, Upload, Dropdown, Menu, Select, notification, Transfer, DatePicker, Avatar, message, InputNumber, Radio } from 'antd';
import { LeftOutlined, LoadingOutlined, EditOutlined, CloseOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import UploadImages from '../../../components/sharing/upload-images'
import CropImage from '../../../components/sharing/crop-image'
import TextEditor from '../../../components/sharing/text-editor'
import moment from 'moment';
import { connect } from 'dva';
import Apploader from '../../../components/loader/loader'

import styles from './style.less';
import { getSubCatbyCategory } from '../../../services/api'
// import { RMIUploader } from "react-multiple-image-uploader";
import MultiImageInput from 'react-multiple-image-input';
import HTMLDecoderEncoder from 'html-encoder-decoder';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
	antdIsValidString,

} from "../../../utils/functions";
import { CKEditor } from "@ckeditor/ckeditor5-react";
const { Text } = Typography;
const { TextArea } = Input;
const timestemp = (new Date()).getTime();
const { RangePicker } = DatePicker;
const formItemLayout = { labelCol: { xs: { span: 24, }, sm: { span: 24, }, } };
const baseUrl = process.env.REACT_APP_ApiUrl
const AddEditPages = props => {
	// const [form] = Form.useForm();
	const [androidForm] = Form.useForm();
	const [milesForm] = Form.useForm();
	const [iosForm] = Form.useForm();
	const { dispatch } = props;
	const [mapId, setMapId] = useState('');
	const [value, setValue] = useState();
	const [value2, setValue2] = useState()
	const [iosValue, setIosValue] = useState();
	const [iosValue2, setIosValue2] = useState()

	const [loading, setLoading] = useState(true);





	useEffect(() => {

		const userData = localStorage.getItem('mapRedius');
		console.log(userData)
		setMapId(userData);


	}, [props.MapMiles]);

	const onChange = (e) => {

		setValue(e.target.value);

	};
	const onChange2 = (e) => {

		setValue2(e.target.value);
	};
	const IosOnChange = (e) => {
		setIosValue(e.target.value);

	};
	const IosOnChange2 = (e) => {

		setIosValue2(e.target.value);
	};
	useEffect(() => {
		let unmounted = false;
		window.scroll(0, 0);



		const fetchData = async () => {
			setLoading(true);
			const userData = localStorage.getItem('mapRedius');
			setMapId(userData);

			try {
				// Fetch Android data
				await props.dispatch({ type: 'AppSetting/getAndroidDetail', payload: { id: "654cc30a1af621588c75008c" } });

				await props.dispatch({ type: 'MapMiles/getMapMiles', payload: { id: userData } });



				// Fetch iOS data
				await props.dispatch({ type: 'AppSetting/getAndroidDetail', payload: { id: "654dca3a9c1c677094b5e768" } });
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [dispatch]);

	const validateMaximumMiles = (_, value) => {
		if (value < 500) {
			return Promise.reject('Maximum meeter must be 10000');
		}
		return Promise.resolve();
	};
	const validateInput = (_, value) => {
		if (/^[-+]?[0-9]+\.[0-9]+$/.test(value)) {
			return Promise.reject('Please enter numbers only');
		}
		return Promise.resolve();
	};






	useEffect(() => {

		let unmounted = false;

		if (props.MapMiles.detail && props.MapMiles.detail.data) {
			const data2 = props.MapMiles.detail.data;


			console.log('data2', data2)
			setMapId(data2._id);
			milesForm.setFieldsValue({
				['radius']: data2.radius,

			});
		}

		if (props.AppSetting && props.AppSetting.detail.settings) {
			const data = props.AppSetting.detail.settings;


			if (data.platform === 'android') {
				setValue(data.isPlatformMaintenance);
				setValue2(data.isPlatformForceUpdate);

				androidForm.setFieldsValue({
					['platformMessage']: data.platformMessage,
					['platformVersion']: data.platformVersion,
					['platformLink']: data.platformLink,
				});
			}
			if (data.platform === 'ios') {
				setIosValue(data.isPlatformMaintenance);
				setIosValue2(data.isPlatformForceUpdate);

				iosForm.setFieldsValue({
					['platformMessage']: data.platformMessage,
					['platformVersion']: data.platformVersion,
					['platformLink']: data.platformLink,
				});
			}
		}

		return () => {
			unmounted = true;
		};
	}, [props.AppSetting]);


	const onFinish = async (val, platform) => {


		setLoading(true);


		val['id'] = platform === 'android' ? "654cc30a1af621588c75008c" : "654dca3a9c1c677094b5e768";
		val['isPlatformMaintenance'] = platform === 'android' ? value : iosValue;
		val['isPlatformForceUpdate'] = platform === 'android' ? value2 : iosValue2;

		try {
			await dispatch({ type: 'AppSetting/AddPages', payload: val });
			setLoading(false);
			window.location.reload();
		} catch (error) {
			console.error('Update failed:', error);
			setLoading(false);
		}


	};



	const onFinishForMapMiles = async (val) => {


		setLoading(true);



		if (mapId) {

			val['id'] = mapId;
		}


		try {

			await dispatch({ type: 'MapMiles/addMapMile', payload: val });
			setLoading(false);


		} catch (error) {
			console.error('Update failed:', error);
			setLoading(false);
		}





	};


	return (
		<div>
			<Apploader show={loading} />

			{/* Maximum miles card */}
			<Card title={'Maximum Miles'} style={{ marginTop: "0" }}>

				<Form {...formItemLayout} form={milesForm} name="loc_info" layout="vertical" onFinish={(val) => onFinishForMapMiles(val)} className="innerFields"  >






					<Row style={{ marginBottom: '0.625rem' }}>
						<Col span={8}>
							<span style={{ color: '#666', fontWeight: '700' }}>Enter Miles</span>
						</Col>
						<Col span={16}>
							<Form.Item
								name="radius"
								rules={[
									{ required: true, message: 'Please enter miles' },

								]}
							>
								<Input placeholder="Enter miles" type='number' />
							</Form.Item>
						</Col>
					</Row>


					<Form.Item className="mb-0">
						<Button type="primary" className="btn-w25 btn-primary-light" onClick={() => milesForm.submit()}  >
							{'Update'}
						</Button>
					</Form.Item>




				</Form>

			</Card>
			<div style={{ marginTop: "20px" }}>
				<Card title={'Android Settings'} style={{ marginTop: "0" }}>

					<Form {...formItemLayout} form={androidForm} name="loc_info" layout="vertical" onFinish={(val) => onFinish(val, 'android')} className="innerFields"  >




						<Row style={{ marginBottom: '0.625rem' }}>
							<Col span={8}>
								<span style={{ color: '#666', fontWeight: '700' }}>Android maintenance</span>
							</Col>
							<Col span={16}>

								<Radio.Group onChange={onChange} value={value} name="isPlatformMaintenance">
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>



							</Col>
						</Row>
						<Row style={{ marginBottom: '0.625rem' }}>
							<Col span={8}>
								<span style={{ color: '#666', fontWeight: '700' }}>Android Force Update</span>
							</Col>
							<Col span={16}>

								<Radio.Group onChange={onChange2} value={value2} name="isPlatformForceUpdate">
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>


							</Col>
						</Row>

						{/* <Row style={{ marginBottom: '0.625rem' }}>
							<Col span={8}>
								<span style={{ color: '#666', fontWeight: '700' }}>Android Message</span>
							</Col>
							<Col span={16}>
								<Form.Item name="platformMessage"    >

									<Input placeholder="Android Message" />
								</Form.Item>
							</Col>
						</Row> */}
						<Row style={{ marginBottom: '0.625rem' }}>
							<Col span={8}>
								<span style={{ color: '#666', fontWeight: '700' }}>Android Version</span>
							</Col>
							<Col span={16}>
								<Form.Item name="platformVersion"    >

									<Input placeholder="Android Version" text='number' />
								</Form.Item>
							</Col>
						</Row>
						<Row style={{ marginBottom: '0.625rem' }}>
							<Col span={8}>
								<span style={{ color: '#666', fontWeight: '700' }}>Android App Link</span>
							</Col>
							<Col span={16}>
								<Form.Item name="platformLink"    >

									<Input placeholder="Android App Link" />
								</Form.Item>
							</Col>
						</Row>

						<Form.Item className="mb-0">
							<Button type="primary" className="btn-w25 btn-primary-light" onClick={() => androidForm.submit()}  >
								{'Update'}
							</Button>
						</Form.Item>




					</Form>

				</Card>

			</div>
			{/* Ios Settings */}

			<div style={{ marginTop: "20px" }}>
				<Card title=
					{'IOS Settings'} style={{ marginTop: "0" }}>

					<Form {...formItemLayout} form={iosForm} name="loc_info" layout="vertical" onFinish={(val) => onFinish(val, 'ios')} className="innerFields"  >




						<Row style={{ marginBottom: '0.625rem' }}>
							<Col span={8}>
								<span style={{ color: '#666', fontWeight: '700' }}>Ios maintenance</span>
							</Col>
							<Col span={16}>

								<Radio.Group onChange={IosOnChange} value={iosValue} name="isPlatformMaintenance">
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>



							</Col>
						</Row>
						<Row style={{ marginBottom: '0.625rem' }}>
							<Col span={8}>
								<span style={{ color: '#666', fontWeight: '700' }}>Ios Force Update</span>
							</Col>
							<Col span={16}>

								<Radio.Group onChange={IosOnChange2} value={iosValue2} name="isPlatformForceUpdate">
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>


							</Col>
						</Row>

						{/* <Row style={{ marginBottom: '0.625rem' }}>
							<Col span={8}>
								<span style={{ color: '#666', fontWeight: '700' }}>Ios Message</span>
							</Col>
							<Col span={16}>
								<Form.Item name="platformMessage"    >

									<Input placeholder="Ios Message" />
								</Form.Item>
							</Col>
						</Row> */}
						<Row style={{ marginBottom: '0.625rem' }}>
							<Col span={8}>
								<span style={{ color: '#666', fontWeight: '700' }}>Ios Version</span>
							</Col>
							<Col span={16}>
								<Form.Item name="platformVersion"    >

									<Input placeholder="Ios Version" text='number' />
								</Form.Item>
							</Col>
						</Row>
						<Row style={{ marginBottom: '0.625rem' }}>
							<Col span={8}>
								<span style={{ color: '#666', fontWeight: '700' }}>Ios App Link</span>
							</Col>
							<Col span={16}>
								<Form.Item name="platformLink"    >

									<Input placeholder="Ios App Link" />
								</Form.Item>
							</Col>
						</Row>

						<Form.Item className="mb-0">
							<Button type="primary" className="btn-w25 btn-primary-light" onClick={() => iosForm.submit()} >
								{'Update'}
							</Button>
						</Form.Item>




					</Form>

				</Card>
			</div>
		</div>
	)
};

export default connect(({ AppSetting, global, loading, MapMiles }) => ({
	AppSetting: AppSetting,
	MapMiles: MapMiles,
	global: global
}))(AddEditPages);