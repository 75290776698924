import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Image, Space, Row, Col, Empty, Modal, Card, Typography, Alert, Form, Input, Checkbox, Button, Switch, Upload, Dropdown, Menu, Select, notification, Transfer, DatePicker, Avatar, message, Descriptions } from 'antd';
import { LeftOutlined, LoadingOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import UploadImages from '../../../components/sharing/upload-images'
import CropImage from '../../../components/sharing/crop-image'
import TextEditor from '../../../components/sharing/text-editor'
import moment from 'moment';
import { connect } from 'dva';
import styles from './style.less';
import Apploader from '../../../components/loader/loader'


const { Text } = Typography;
const { TextArea } = Input;
const timestemp = (new Date()).getTime();
const { RangePicker } = DatePicker;
const formItemLayout = { labelCol: { xs: { span: 24, }, sm: { span: 24, }, } };
const dateFormat = 'MM/DD/YYYY';

const AddEditUser = props => {
	const [form] = Form.useForm();
	const { dispatch, category } = props;
	const [visible, setVisible] = useState(false);
	const [detail, setDetail] = useState({});
	const [random, setRandom] = useState();
	const [itemId, setItemId] = useState()
	const [imageUrl, setImageUrl] = useState('');
	const [picModel, setPicModel] = useState(false);
	const [loading, setLoading] = useState(true);
	const [InquiryData, setInquiryData] = useState('');
	const [Inquiry, setInquiry] = useState('');
	const [count, setCount] = useState(0)
	const [ecount, setECount] = useState(0)
	const [dcount, setDCount] = useState(0)
	const [btnDis, setBtnDis] = useState(false)
	const [show, setShow] = useState(false)
	const [isModalVisible, setIsModalVisible] = useState(false);

	const handleImageClick = () => {
		setIsModalVisible(true);
	};

	const handleModalClose = () => {
		setIsModalVisible(false);
	};


	useEffect(() => {
		// console.log("date6gt76gt6fr ", detail.userLogin)
		let unmounted = false;
		window.scroll(0, 0);
		if (props.match && props.match.params.id) {
			setItemId(props.match.params.id)
			DetailFun(props.match.params.id)
		} else {
			form.resetFields();
			setImageUrl('');
		}
		return () => { unmounted = true; }
	}, [dispatch])


	const DetailFun = async (id) => {
		setLoading(true);


		try {
			await props.dispatch({ type: 'users/getDetail', payload: { _id: id, profile_id: id } });
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setLoading(false);
		}

		// props.dispatch({ type: 'users/getDetail', payload: { _id: id, profile_id: id } });
	}



	useEffect(() => {
		console.log(detail)





		// detail
		if (props.match && props.match.params.id) {

			let detail = props.users.detail;
			console.log(detail)
			if (detail && detail.status) {

				let data = detail.userLogin;
				setDetail({

					name: data.name,
					username: data.username,
					email: detail.userLogin.email,
					mobile_number: data.mobile_number,
					date: moment(data.created_at).format(dateFormat),
					isEmailVerified: detail.userLogin.isEmailVerified,
					image: data.image,
					//isMobileVerified: data.isMobileVerified,
					roles: detail.userLogin.roles,
				})

			}

		}

	}, [props.users.detail])

	const cancelFun = () => {

		setShow(false)

	}

	const backFun = () => {

		props.history.push('/users')
	}
	const modalWidth = 500;


	return (
		<div>

			<Apploader show={loading} />

			<Card title={
				<Space>
					<LeftOutlined onClick={backFun} style={{ fontSize: 20, marginBottom: 6 }} />
					<span >User Details</span>
				</Space>
			}>
				{/* <Modal visible={show} title={'Image'} onCancel={cancelFun} footer={null} bodyStyle={{ margin: 10 }}>
					<div style={{ display: 'flex', justifyContent: "left", padding: "20px 10px" }} >
						<Avatar shape="square" size={400} src={detail.image} style={{ marginBottom: '2px' }} />

					</div>
				</Modal> */}

				{/* <div style={{ display: 'flex', justifyContent: "left", padding: "20px 10px", borderRadius: 20 }}>
					<Image
						width={200}
						height={200}
						style={{ borderRadius: 20 }}
						src={detail.image}
						placeholder={
							<div style={{ display: 'flex', justifyContent: "left", padding: "20px 10px", borderRadius: 20 }} >
								<Image
									preview={false}
									style={{ borderRadius: 20 }}
									src={detail.image}
									width={200}
									height={20}
								/>
							</div>
						}
					/>


				</div> */}

				<div style={{ display: 'flex', borderRadius: 20 }}>
					<Image
						width={200}
						height={200}
						preview={false}
						style={{ borderRadius: 20 }}
						src={detail.image}
						onClick={handleImageClick}
					/>

					<Modal
						visible={isModalVisible}
						onCancel={handleModalClose}
						footer={null}
						width={modalWidth}

						bodyStyle={{
							borderRadius: 20,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '90vh', // Set the height to 100% of the viewport height
						}}
					>
						<Image
							preview={false}
							style={{ borderRadius: 20 }}
							src={detail.image}
							width={modalWidth}
							height={modalWidth} // Set the height to the same value as width
						/>
					</Modal>
				</div>




				<Row style={{ marginBottom: '0.625rem' }}>
					<Col span={8}>
						<span style={{ color: '#666', fontWeight: '700' }}>User Name</span>
					</Col>
					<Col span={16}>
						{detail && detail.name}
					</Col>
				</Row>
				<Row style={{ marginBottom: '0.625rem' }}>
					<Col span={8}>
						<span style={{ color: '#666', fontWeight: '700' }}>Email</span>
					</Col>
					<Col span={16}>
						{detail && detail.email}

					</Col>
				</Row>

				<Row style={{ marginBottom: '0.625rem' }}>
					<Col span={8}>
						<span style={{ color: '#666', fontWeight: '700' }}>Profile Created On</span>
					</Col>
					<Col span={16}>
						{detail && detail.date}
					</Col>
				</Row>
				<Row style={{ marginBottom: '0.625rem' }}>
					<Col span={8}>
						<span style={{ color: '#666', fontWeight: '700' }}>isEmailVerified</span>
					</Col>
					<Col span={16}>
						{detail && detail.isEmailVerified == true ? 'true' : 'false'}
					</Col>
				</Row>
				<Row style={{ marginBottom: '0.625rem' }}>
					<Col span={8}>
						<span style={{ color: '#666', fontWeight: '700' }}>Roles</span>
					</Col>
					<Col span={16}>
						{detail && detail.roles}
					</Col>
				</Row>

				{/* <CropImage visible={picModel} closeFun={() => setPicModel(false)} returnImg={getNewImage} resetVal={imageUrl} limit={1} aspect={9 / 12} /> */}

			</Card>

		</div>
	)
};

export default connect(({ users, global, loading }) => ({
	users: users,
	global: global
}))(AddEditUser);